<template>
    <div ref="mapContainer">
        <h3>{{ this.title }}</h3>
    </div>
</template>

<script>
    export default {
        name: 'embedded-map',
        props: ['src', 'title'],
        mounted() {
            let mapScript = document.createElement('script');
            mapScript.setAttribute('src', this.src);
            this.$refs.mapContainer.appendChild(mapScript);
        }
    }
</script>

<style scoped>
    h3 {
        font-family: var(--font-header);
        color: var(--accent);
        font-weight: normal;
        margin: 20px 0 0;
        padding: 5px 10px 5px 10px;
        border: 1px solid var(--accent);
        border-bottom: none;
        background-color: var(--primary);
        text-align: left;
    }
</style>
