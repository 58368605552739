<template>
    <div>
      <h2>Plakát / emléklap</h2>
      <image-galery subfolder="archive2022"></image-galery>
  
      <h2>Programok</h2>
      <div>
        <h3>Kedves Párok!</h3>
        <p>
            Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire. Kissé viharos volt az elmúlt év a covid miatt, az idei házassághete programjait ehhez kapcsolódóan építettük fel.
            A kettesben együtt töltött idővel - "Kapcsolat ápoló túra", a közös imával, az előadással szeretnénk segíteni nektek a kapcsolatotok erősítésében.	
        </p>
        <p>
            Nagyon szeretnénk idén gyertyafényes vacsorát szervezni, kérünk jelezzétek részvételi szándékotokat.
            Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. Ha eddig még nem adtátok meg elérhetőségeteket,
            kérünk osszátok meg velünk a <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel.
        </p>
        <p>A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében. Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!!</p>
        <p>
            Tudjuk, sokaknak nehéz megoldani a gyerekvigyázást, amikor el szeretnének jutni valamelyik programra. Tudunk segíteni, ez ügyben keressétek Hollai Zsófit a +36204404580-as telefonszámon!
        </p>
        <p>
            Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk.
        </p>
        
        <p class="right-align">HH Team</p>
      </div>
      <h2>1. 4 bakancs / 8 kérdés - kapcsolat ápoló túra</h2>
        <div class="list-wrapper">
            <img class="sign" src="../../assets/trials2022/4bakancstabla.jpg">
            <p>
                Idei előadónk, Mihalec Gábor párterapeuta, 8 segítő kérdést írt nekünk, éljetek ezekkel! Jó lenne az előadásig végig járni az utat, de természetesen bármikor sort keríthettek rá.  
                A túra útvonala kissé eltér a már megszokottól, próbáltuk kikerülni az aszfaltozott utakat.
                Február hónapban kihelyezünk néhány segítő táblácskát is az útvonal mentén.
            </p>
            <a href="/4 bakancs 8 kerdes.pdf" download><img class="download-icon" src="../../assets/pdf-icon_black.png"></a>
            <p>
                Ha még nem kaptátok meg az ehhez kapcsolódó feladatlapot, vagy netán elvesztettétek, a pdf ikonra kattintva innen is le tudjátok tölteni.
            </p>

            <embedded-map src="https://www.termeszetjaro.hu/hu/embed/231644644/js?mw=false" title="A túra útvonala"></embedded-map>
        </div>

        <h2>2. Szentségimádás</h2>
        <div class="list-wrapper">
            <p>Legyünk együtt az Úrral egy órát csendben, hagyjuk, hogy betöltsön jelenlétével! Ő biztosan vár minket.</p>
            <p><strong>Nagymaros, Római Katolikus Templom</strong> <br> február 7. hétfő 20 és 21 óra között</p>
        </div>
        
        <h2>3. Előadás</h2>
        <div class="list-wrapper">
            <h3 class="font-weght-normal"><b>Mihalec Gábor:</b> "<i>Vihar álló szerelem</i>"</h3>

            <p>
                Gábor egy 1300 fős kutatást végzett házaspárok között. A több mint 100.000 adat statisztikai elemzése után kikristályosodott 7 gyakorlati terület, amit az előadáson valós életpéldákkal mutat be. A közös pont mindegyikben az, hogy tanulható. Tehát megtanulhatunk jó párként működni. Ehhez pedig kész "edzéstervet" nyújt az előadáson számunkra. 
            </p>

            <p>
                <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
                <br> február 9. szerda este 18.00
            </p>

            <p><strong>Az előadás során maszk viselése kötelező!</strong></p>
        </div>

        <h2>4. Gyertyafényes vacsora</h2>
        <div class="list-wrapper">
            <p class="highlight"><strong>A járványhelyzet miatt elmarad!</strong></p>
            <p>
                Nagyon szeretnénk együtt ünnepelni veletek, de látva a fertőzésszámokat, csak február elején fog kiderülni megtarthajtuk-e a vacsorát.
                Kérünk, feltétlenül jelezzétek részvételi szándékotokat e-mailben a <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címen.
                A jelentkezőket folyamatosan tájékoztatni fogjuk az aktuális helyzetről.
            </p>
            <p>
                <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
                <br> február 11. péntek este 19.00
            </p>
        </div>

        <h2>5. Záró szentmise / istentisztelet</h2>
        <div class="list-wrapper">
            <p>Házassági Fogadalom megújítása, áldás. Ha tudjátok, hozzátok el magatokkal ez esküvői keresztet.</p>
            <p><strong>Nagymaros, Római Katolikus Templom</strong> <br> február 12. szombat 18.00</p>
            <p><strong>Nagymaros, Református Templom</strong> <br> február 13. vasárnap 10.00</p>
        </div>
    </div>
  </template>
  
  <script>
    import EmbeddedMapComponent from './EmbeddedMapComponent.vue';
    import ImageGaleryComponent from './ImageGaleryComponent.vue';
  
    export default {
      name: 'archive-2022',
      components: {
        'embedded-map': EmbeddedMapComponent,
        'image-galery': ImageGaleryComponent
      },
      data: function() {
        return {
          publicPath: process.env.BASE_URL
        }
      }
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .download-icon {
        width: 44px;
        float: left;
        margin-top: 26px;
        margin-right: 8px;
        padding-right: 4px;
    }

    .sign {
        float: left;
        width: 168px;
        margin-top: 24px;
        margin-right: 8px;
        padding-right: 4px;
    }

    @media (max-width: 920px) {
        .sign {
            width: 150px;
        }
    }
  
    h2:first-child, h3:first-child {
      margin-top: 20px;
    }
  </style>
  