var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("Plakát / emléklap")]),_c('image-galery',{attrs:{"subfolder":"archive2022"}}),_c('h2',[_vm._v("Programok")]),_vm._m(0),_c('h2',[_vm._v("1. 4 bakancs / 8 kérdés - kapcsolat ápoló túra")]),_c('div',{staticClass:"list-wrapper"},[_c('img',{staticClass:"sign",attrs:{"src":require("../../assets/trials2022/4bakancstabla.jpg")}}),_c('p',[_vm._v(" Idei előadónk, Mihalec Gábor párterapeuta, 8 segítő kérdést írt nekünk, éljetek ezekkel! Jó lenne az előadásig végig járni az utat, de természetesen bármikor sort keríthettek rá. A túra útvonala kissé eltér a már megszokottól, próbáltuk kikerülni az aszfaltozott utakat. Február hónapban kihelyezünk néhány segítő táblácskát is az útvonal mentén. ")]),_vm._m(1),_c('p',[_vm._v(" Ha még nem kaptátok meg az ehhez kapcsolódó feladatlapot, vagy netán elvesztettétek, a pdf ikonra kattintva innen is le tudjátok tölteni. ")]),_c('embedded-map',{attrs:{"src":"https://www.termeszetjaro.hu/hu/embed/231644644/js?mw=false","title":"A túra útvonala"}})],1),_c('h2',[_vm._v("2. Szentségimádás")]),_vm._m(2),_c('h2',[_vm._v("3. Előadás")]),_vm._m(3),_c('h2',[_vm._v("4. Gyertyafényes vacsora")]),_vm._m(4),_c('h2',[_vm._v("5. Záró szentmise / istentisztelet")]),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Kedves Párok!")]),_c('p',[_vm._v(" Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire. Kissé viharos volt az elmúlt év a covid miatt, az idei házassághete programjait ehhez kapcsolódóan építettük fel. A kettesben együtt töltött idővel - \"Kapcsolat ápoló túra\", a közös imával, az előadással szeretnénk segíteni nektek a kapcsolatotok erősítésében. ")]),_c('p',[_vm._v(" Nagyon szeretnénk idén gyertyafényes vacsorát szervezni, kérünk jelezzétek részvételi szándékotokat. Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. Ha eddig még nem adtátok meg elérhetőségeteket, kérünk osszátok meg velünk a "),_c('a',{attrs:{"href":"mailto:marosvet75 [kukac] gmail.com"}},[_vm._v("marosvet75 [kukac] gmail.com")]),_vm._v(" címre küldött e-maillel. ")]),_c('p',[_vm._v("A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében. Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!!")]),_c('p',[_vm._v(" Tudjuk, sokaknak nehéz megoldani a gyerekvigyázást, amikor el szeretnének jutni valamelyik programra. Tudunk segíteni, ez ügyben keressétek Hollai Zsófit a +36204404580-as telefonszámon! ")]),_c('p',[_vm._v(" Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk. ")]),_c('p',{staticClass:"right-align"},[_vm._v("HH Team")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/4 bakancs 8 kerdes.pdf","download":""}},[_c('img',{staticClass:"download-icon",attrs:{"src":require("../../assets/pdf-icon_black.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('p',[_vm._v("Legyünk együtt az Úrral egy órát csendben, hagyjuk, hogy betöltsön jelenlétével! Ő biztosan vár minket.")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Római Katolikus Templom")]),_vm._v(" "),_c('br'),_vm._v(" február 7. hétfő 20 és 21 óra között")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('h3',{staticClass:"font-weght-normal"},[_c('b',[_vm._v("Mihalec Gábor:")]),_vm._v(" \""),_c('i',[_vm._v("Vihar álló szerelem")]),_vm._v("\"")]),_c('p',[_vm._v(" Gábor egy 1300 fős kutatást végzett házaspárok között. A több mint 100.000 adat statisztikai elemzése után kikristályosodott 7 gyakorlati terület, amit az előadáson valós életpéldákkal mutat be. A közös pont mindegyikben az, hogy tanulható. Tehát megtanulhatunk jó párként működni. Ehhez pedig kész \"edzéstervet\" nyújt az előadáson számunkra. ")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Szent Márton Közösségi Ház")]),_c('br'),_vm._v(" február 9. szerda este 18.00 ")]),_c('p',[_c('strong',[_vm._v("Az előadás során maszk viselése kötelező!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('p',{staticClass:"highlight"},[_c('strong',[_vm._v("A járványhelyzet miatt elmarad!")])]),_c('p',[_vm._v(" Nagyon szeretnénk együtt ünnepelni veletek, de látva a fertőzésszámokat, csak február elején fog kiderülni megtarthajtuk-e a vacsorát. Kérünk, feltétlenül jelezzétek részvételi szándékotokat e-mailben a "),_c('a',{attrs:{"href":"mailto:marosvet75 [kukac] gmail.com"}},[_vm._v("marosvet75 [kukac] gmail.com")]),_vm._v(" címen. A jelentkezőket folyamatosan tájékoztatni fogjuk az aktuális helyzetről. ")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Szent Márton Közösségi Ház")]),_c('br'),_vm._v(" február 11. péntek este 19.00 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('p',[_vm._v("Házassági Fogadalom megújítása, áldás. Ha tudjátok, hozzátok el magatokkal ez esküvői keresztet.")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Római Katolikus Templom")]),_vm._v(" "),_c('br'),_vm._v(" február 12. szombat 18.00")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Református Templom")]),_vm._v(" "),_c('br'),_vm._v(" február 13. vasárnap 10.00")])])
}]

export { render, staticRenderFns }